import { Controller } from "@hotwired/stimulus";
import IMask from "imask";

export default class extends Controller {
  static values = {
    pattern: String,
    type: String,
  };

  connect() {
    if (this.typeValue == "time") {
      this.mask = IMask(this.element, this.timeMaskOptions());
      return;
    }

    this.mask = IMask(this.element, {
      mask: this.patternValue,
      lazy: false,
      overwrite: true,
      autofix: true,
    });
  }

  disconnect() {
    this.mask?.destroy();
  }

  timeMaskOptions() {
    return {
      overwrite: true,
      autofix: true,
      mask: "hh:mm",
      lazy: false,
      blocks: {
        hh: {
          mask: IMask.MaskedRange,
          from: 0,
          to: 23,
          maxLength: 2,
        },
        mm: {
          mask: IMask.MaskedRange,
          from: 0,
          to: 59,
          maxLength: 2,
        },
      },
    };
  }
}
